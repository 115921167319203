// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-business-directory-js": () => import("./../../../src/pages/business-directory.js" /* webpackChunkName: "component---src-pages-business-directory-js" */),
  "component---src-pages-business-loans-js": () => import("./../../../src/pages/business-loans.js" /* webpackChunkName: "component---src-pages-business-loans-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-industry-post-js": () => import("./../../../src/templates/industry-post.js" /* webpackChunkName: "component---src-templates-industry-post-js" */),
  "component---src-templates-loan-post-js": () => import("./../../../src/templates/loan-post.js" /* webpackChunkName: "component---src-templates-loan-post-js" */),
  "component---src-templates-review-post-js": () => import("./../../../src/templates/review-post.js" /* webpackChunkName: "component---src-templates-review-post-js" */),
  "component---src-templates-reviews-js": () => import("./../../../src/templates/reviews.js" /* webpackChunkName: "component---src-templates-reviews-js" */)
}

